<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Create New Question
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          v-if="localQuestion!=null||localQuestion!=undefined"
          @submit.prevent="handleSubmit(handleQuestionSubmit)"
          @reset.prevent="resetForm"
        >

        

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Question"
            rules="required"
          >
            <b-form-group
              label="Question"
              label-for="SurveyCode"
            >
            <b-form-textarea
        id="textarea-auto-height"
           v-model="localQuestion.description"
        placeholder="Enter question text here"
        rows="3"
        max-rows="8"
      />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        
     
 

          <!-- User Role -->
        
            <b-form-group
              label="Question Type"
              label-for="user-role"
              :state="selectedCategory!=null"
            >
               <examquestion-type-picker
             :initialCategories="selectedCategory"
              @selectionChanged="SurveyTypeUpdated"
            />
             
            </b-form-group>
  
           


<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='RANGE_SELECTION'"> 
             <b-form-group
              label="Value & text for  minimum answer"
              label-for="user-role"
              :state="!(localQuestion.questionTypeKey==null||localQuestion.questionTypeKey==0)"
            >

            <b-row>
            
           <b-col cols="3">
  
       <b-form-input
                id="SurveyCode"
                v-model="localQuestion.additionalInfo.minValue"
             
               placeholder="1"
                trim
              />
   
    </b-col>
              <b-col>
  
       <b-form-input
                id="SurveyCode"
                v-model="localQuestion.additionalInfo.minText"
             
               placeholder="Bad"
                trim
              />
   
    </b-col>
  <b-col cols="5">
               
             <b-form-rating
      no-border
      v-model="localQuestion.additionalInfo.minValue"
     disabled
      show-value
      variant="warning"
      inline
      precision="0"
    />
        </b-col>
            </b-row>

             

             
            </b-form-group>
        
             <b-form-group
              label="Value & text for  maximum answer"
              label-for="user-role"
              :state="!(localQuestion.questionTypeKey==null||localQuestion.questionTypeKey==0)"
            >

            <b-row>
             
            <b-col  cols="3">
  
       <b-form-input
                id="SurveyCode"
                
                  v-model="localQuestion.additionalInfo.maxValue"
               placeholder="5"
                trim
              />
   
    </b-col>

              <b-col>
  
       <b-form-input
                id="SurveyCode"
                v-model="localQuestion.additionalInfo.maxText"
              
               placeholder="Amazing"
                trim
              />
   
    </b-col>
 <b-col cols="5">
               
             <b-form-rating
      no-border
      v-model="maxValue"
      disabled
      show-value
      variant="warning"
      inline
      precision="0"
    />
        </b-col>
            </b-row>

             

             
            </b-form-group>
         
</div>

<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='MULTIPLE_SELECTION'"> 
      
           
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
  <b-form-group
              label="Available Answers"
              label-for="item-name"
            >  
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in localQuestion.questionAnswers"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
        <b-col
            lg="1"
            md="1"
          >
           

       <b-form-checkbox
        v-model="item.selected"
       value="true"
      class="custom-control-primary"
    >
       
    </b-form-checkbox>

          </b-col>
          <!-- Item Name -->
          <b-col md="9">
          
              <b-form-textarea
                id="item-name"
                type="text"
                v-model="item.answer"
                placeholder="Another answer"
              />
          
          </b-col>

          
 
 

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="2"
          
          >
            
            <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      class="btn-icon"       @click="removeItem(index)"
    >
      <feather-icon
                icon="XIcon"
             
              />
    </b-button>

          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
</b-form-group>
      </b-form>
 
    <b-button
     
        v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="flat-info"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Another Option</span>
    </b-button>
  </div>    
         
</div>
        
<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='SINGLE_SELECTION'"> 
              <div>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
  <b-form-group
              label="Available Answers"
              label-for="item-name"
            >  
        <!-- Row Loop -->
        <b-row
          v-for="(item,index) in  localQuestion.questionAnswers"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
        <b-col
            lg="1"
            md="1"
          >
            <b-form-radio
          v-model="localQuestion.acceptedAnswer"
       :value="item.value"
      class="demo-inline-spacing"
      name="radio-validation"
    >
      
    </b-form-radio>
          </b-col>
          <!-- Item Name -->
          <b-col md="9">
          
              <b-form-textarea
                id="item-name"
                type="text"
                v-model="item.answer"
                placeholder="Another answer"
              />
          
          </b-col>

          
 
 

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="2"
          
          >
            
            <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      class="btn-icon"       @click="removeItem(index)"
    >
      <feather-icon
                icon="XIcon"
             
              />
    </b-button>

          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
</b-form-group>
      </b-form>
    </div>
    <b-button
     
        v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="flat-info"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Another Option</span>
    </b-button>
  </div>
         
</div>

<div v-if="localQuestion.questionTypeKey!=null&&localQuestion.questionTypeKey==='YES_NO_QUESTION'"> 
                 <div>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
  <b-form-group
              label="Choose Correct Answers"
              label-for="item-name"
            >  
        <!-- Row Loop -->
        <b-row
          v-for="(item,index   ) in  localQuestion.questionAnswers "
          :id="item.id"
          :key="item.id"
          ref="row"
        >
        <b-col  lg="1"  md="1">
            <b-form-radio
          v-model="localQuestion.acceptedAnswer"
       :value="index==0?'YES':'NO'"
      class="demo-inline-spacing"  name="radio-validation"  >
    
    </b-form-radio>
          </b-col>
            <b-col  md="9">
            
   {{ item.answer}}
        
          </b-col>
          

      
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
</b-form-group>
      </b-form>
    </div>
  
  </div>
         
         
</div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ localQuestion.id ? 'Update' : 'Create' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
BFormCheckbox,BFormTextarea,BFormRating, BRow, BCol,  BSidebar, BForm, BFormGroup, BFormInput,
  BButton,  BFormRadioGroup,BFormRadio,
    BFormInvalidFeedback,
    BFormValidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref ,toRefs,watch,inject } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import router from '@/router'
import ExamquestionTypePicker from '../components/ExamQuestionTypePicker.vue' 
import { heightTransition } from '@core/mixins/ui/transition'
 

export default {
  components: {
    BFormCheckbox,
    BFormRating,
    BFormTextarea,
    BSidebar,
     BRow, BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
      BFormRadioGroup,
        BFormRadio,
    BFormInvalidFeedback,
    BFormValidFeedback,
ExamquestionTypePicker, 
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
   mixins: [heightTransition],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    questionCount:0,
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    activeQuestion:null
  
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      minValue: 1,
      selectedCategory:null,
      questionTypeKey: '',
      maxValue:10,
     
      nextTodoId: 2,
        value: null,
      options: [
       
      ],
      localQuestion: { title: '',
      examid: '',
      description:'',
      questionType: '',
      questionTypeKey: '',
      allowComment:false,
      acceptedAnswers: [],
      acceptedAnswer:null,
      questionAnswers:[{
        id: 1,
        selected:false,
        value: this.generateUUID(),
        answer:null
       
      }],
      additionalInfo: {
      
        minValue:1,
          maxValue:5,
          minText:"Bad",
          maxText:"Amazing"
      }
      }
    }
  },
   mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    state() {
      return Boolean(this.value)
    },
    answers(){
     
  console.log("---");
       console.log(this.localQuestion.questionAnswers);


      return   JSON.parse(JSON.stringify( this.localQuestion.questionAnswers)) ;

    }
  },
  watch: {
   
    activeQuestion(newVal,oldVal){
      if(newVal){
        this.localQuestion=newVal;
        console.log("question updated");
        console.log(this.localQuestion);
       this.selectedCategory= this.localQuestion.questionTypeKey;
       
      }
    }
    },

  methods:{

    resetForm(){

    },
      generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
},
    repeateAgain() {

     

      
      this.localQuestion.questionAnswers.push({
       
        selected: false,
        value:this.generateUUID(),
        answer: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
       var items=this.localQuestion.questionAnswers;

//        var result = items.filter(obj => {
//   return obj.value=== item
// })
 

       items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
        handleQuestionSubmit() {
        console.log("Exam ADD Pquestion  Create triggered");

 
     
      console.log(this.localQuestion);
         var examid= store.getters['app/currentUnit'] ;;
     this.localQuestion.examid=examid;

        this.localQuestion.questionOrder=this.questionCount+1;
       


      store.dispatch('app-exam-questions/addExamQuestion', this.localQuestion)
        .then(() => {
          
        this.selectedCategory=null;
        this.userData= { title: '',
      examid: '',
      description:'',
      questionType: '',
      questionTypeKey: '',
      allowComment:false,
      acceptedAnswers: [],
      acceptedAnswer:null,
      questionAnswers:[{
        id: 1,
        selected:false,
        value: this.generateUUID(),
        answer:null
       
      }],
      additionalInfo: {
      
        minValue:1,
          maxValue:5,
          minText:"Bad",
          maxText:"Amazing"
      }};


         this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        })
    },
      SurveyTypeUpdated(payload) {
      console.log("question result in info ");
      console.log(payload);
      if (payload==null)
      {
  
      this.questionTypeKey=null;
     this.questionType=null;
          this.localQuestion.questionType = null,
    this.localQuestion.questionTypeKey = null
      } else
    {  
    console.log("question type  data updated "+payload.typekey);

     this.questionTypeKey=payload.typekey;
     this.questionType=payload.title;
      this.selectedCategory=payload;

       this.localQuestion.questionType = payload.title,
     this.localQuestion.questionTypeKey = payload.typekey

            if (payload.typekey=="YES_NO_QUESTION")
            {
              this.localQuestion.questionAnswers=
              [
                {
        id: 1,
        selected:false,
        value: 'YES',
        answer:"YES"
       
      },
      {
        id: 2,
        selected:false,
        value: 'NO',
        answer:"NO"
       
      }
              ]

            }

    }

    },
     
  },
  setup(props, { emit }) {

console.log("load props");    
console.log(props);

return;
    const generateUUID=function () { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

    
 

    var examid= store.getters['app/currentUnit'] ;;



 

  const userData = ref(null)
 
console.log("active question");
const { activeQuestion } = toRefs(props)

watch(   activeQuestion, (currentValue, oldValue) => {
  console.log('connected to activeQuestion');
      console.log(currentValue);
      
      if (currentValue!=null)
      {
        userData.value=JSON.parse(JSON.stringify(currentValue));
    
      } else

      {
       userData.value= ref(JSON.parse(JSON.stringify(blankUserData)));
      }
 
    });

  
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

   
 

    const onSubmit = () => {
        console.log("Exam ADD Pquestion  Create triggered");
        console.log( userData.value);
      
        
        userData.value.questionOrder=props.questionCount+1;



      store.dispatch('app-Exam-questions/addExamQuestion', userData.value)
        .then(() => {
         resetuserData();
        

          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>